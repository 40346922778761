import { TFunction } from 'i18next';

export const validateField = (
  setError: any,
  error: string,
  fieldValue: any,
  setHideError: any,
  validationType: string,
  t: TFunction,
) => {
  const errorMessages = {
    name: t('translation:auth.name_is_required.message'),
    phone: t('translation:auth.phone_is_required.message'),
    email: t('translation:auth.email_is_required.message'),
    password: t('translation:auth.password_is_required.message'),
    nameLength: t('translation:auth.name_error.message'),
    passwordLength: t('translation:auth.password_error.message'),
    phoneFormat: t('translation:auth.phone_error.message'),
    emailFormat: t('translation:auth.email_error.message'),
  };
  switch (validationType) {
    case 'name':
      if (fieldValue) {
        if (fieldValue.length < 3) {
          setError(errorMessages.nameLength);
        } else {
          setError('');
          setHideError(true);
        }
      } else {
        setError(errorMessages.name);
      }

      break;

    case 'phone':
      if (fieldValue) {
        const phoneRegex = /\d/; //^(\+995|995)?5[0-9]{8}$
        fieldValue = fieldValue.replace(/\s/g, '');

        if (!phoneRegex.test(fieldValue)) {
          setError(errorMessages.phoneFormat);
        } else {
          setError('');
          setHideError(true);
        }
      } else {
        setError(errorMessages.phone);
      }

      break;

    case 'email':
      if (fieldValue) {
        const emailRegex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!emailRegex.test(fieldValue)) {
          setError(errorMessages.emailFormat);
        } else {
          setError('');
          setHideError(true);
        }
      } else {
        setError(errorMessages.email);
      }

      break;

    case 'password':
      if (fieldValue) {
        if (fieldValue.length < 6) {
          setError(errorMessages.passwordLength);
        } else {
          setError('');
          setHideError(true);
        }
      } else {
        setError(errorMessages.password);
      }

      break;
  }
};

export const validateSearchQuery = (query: string, setError: (error: string | null) => void, t: any): boolean => {
  const len = query.split(' ').join('').length;
  const regex = /^(?=.*[a-zA-Zа-яА-ЯёЁ0-9])[a-zA-Zа-яА-ЯёЁ0-9()\- /]+$/;

  if (query.length > 0) {
    const trimmedQuery = query.trim();

    if (len < 3) {
      setError(t('translation:header.search_field_error_length.message') as string);
      return false;
    }

    if (trimmedQuery.length > 30) {
      setError(t('translation:header.search_field_error_max_length.message') as string);
      return false;
    }

    if (!regex.test(trimmedQuery)) {
      setError(t('translation:header.search_field_error_invalid_chars.message') as string);
      return false;
    }
  }

  setError(null);
  return true;
};
