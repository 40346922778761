import React, { FC, useState, useRef, useEffect } from 'react';
import '../filterOptions.scss';
import { ILocation } from '../../../../types';
import { t } from 'i18next';
import { Checkbox, FormControlLabel, RadioGroup } from '@mui/material';
import { categoryFields, CheckedElementStyle, RadioGroupStyle } from '../index';
import { useActions, useAppSelector } from '../../../../hooks/redux';
import { ISearchRequest } from '../../../../services/types';
import useUpdateQueryParams from '../../../../hooks/useUpdateQueryParams';
import RightArrow from '../../../../img/sort-img/right-arrow.svg';

interface ILocationSelect {
  locations: ILocation[];
  search: ({}: ISearchRequest) => void;
}

const LocationSelect: FC<ILocationSelect> = ({ locations, search }) => {
  const { setLocationsStates, setPosts } = useActions();
  const {
    currentPage,
    isHandmade,
    query,
    categoryIds,
    sortBy,
    postsStates,
    locationsStates,
    fieldsStates,
    priceValue,
  } = useAppSelector((state) => state.filterReducer);

  const { updateQueryParams } = useUpdateQueryParams();

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLParagraphElement>(null);

  const handleOutsideClick = (e: MouseEvent) => {
    const target = e.target as Node;
    if (
      popupRef.current &&
      !popupRef.current.contains(target) &&
      buttonRef.current &&
      !buttonRef.current.contains(target)
    ) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regions = { ...locationsStates };

    if (event.target.checked) {
      setPosts(undefined);
      regions[event.target.name] = event.target.id;
    } else {
      delete regions[event.target.name];
    }
    setLocationsStates(regions);

    const requestObject: ISearchRequest = {
      category_id: categoryIds,
      page: 1,
      sort_by: sortBy,
      used:
        (!postsStates.used && !postsStates.new) ||
        (postsStates.used && postsStates.new)
          ? undefined
          : postsStates.used,
      category_fields: categoryFields(fieldsStates),
      price: priceValue,
    };

    if (query) {
      requestObject.q = query;
    }

    if (isHandmade) {
      requestObject.handmade = isHandmade;
    }

    requestObject.location_id = Object.values(regions).length
      ? Object.values(regions)
      : undefined;

    updateQueryParams({
      page: currentPage,
      used: postsStates.used,
      new: postsStates.new,
      sort_by: sortBy,
      handmade: isHandmade,
      price_min: (priceValue as number[])[0],
      price_max: (priceValue as number[])[1],
      locations: Object.values(regions),
      fields: encodeURIComponent(
        JSON.stringify(categoryFields(fieldsStates))
      ),
    });

    search(requestObject);
  };

  const getLocationsList = () => {
    return locations.map((location) => (
      <div className="location-item" key={location.id}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(locationsStates[location.name]) || false}
              onChange={handleChange}
              name={location.name}
              id={location.id}
              sx={CheckedElementStyle}
            />
          }
          label={location.name}
        />
      </div>
    ));
  };

  return (
    <div>
      <p className="filter-menu__title">
        {t('translation:filter.region.message')}
      </p>

      <RadioGroup sx={RadioGroupStyle}>
        {getLocationsList().slice(0, 4)}
      </RadioGroup>

      <p
        ref={buttonRef}
        className="show-more-options"
        onClick={() => setShowPopup((prev) => !prev)}
      >
        {t('filter.show_all_options.message', { count: locations.length })}

        <img src={RightArrow} alt="Right Arrow" className="right-arrow-icon" />

        {showPopup && (
          <div
            className="popup-container"
            ref={popupRef}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="popup-content">{getLocationsList()}</div>
          </div>
        )}
      </p>
    </div>
  );
};

export default LocationSelect;
