import React, {FC, useState} from 'react';
import {FormControl, IconButton, InputAdornment, TextField, FormHelperText} from '@mui/material';
import {searchInputStyle} from './searchInputStyle';
import {Icon} from '../../../default_components';
import {t} from 'i18next';
import {useActions} from '../../../../hooks/redux';
import {useNavigate, useParams} from 'react-router-dom';
import {validateSearchQuery} from '../../../../lib/textFieldsValidators';
import useNavigateWithLocale from '../../../../hooks/useNavigateWithLocale';

interface ISearchInput {
  closeDrawer?: () => void;
}

const SearchInput: FC<ISearchInput> = ({closeDrawer}) => {
  const {setPosts, setQuery: setStoredQuery, setIsHandmade} = useActions();

  const [query, setQuery] = useState('');
  const [error, setError] = useState<string | null>(null);

  const {category_id, query: UrlQuery} = useParams();
  const navigateWithLocale = useNavigateWithLocale();

  const handleSearch = (): void => {

    if (validateSearchQuery(query, setError, t)) {
      if (query !== UrlQuery) {
        navigateWithLocale(`/category/${category_id}/${query}`);
        setPosts(undefined);
        setIsHandmade(false);
        setStoredQuery(query);
      }
    }
  };

  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      closeDrawer && closeDrawer();
      handleSearch();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setError(null);
  };

  return (
    <FormControl sx={searchInputStyle} error={!!error}>
      <TextField
        autoComplete="off"
        onChange={handleChange}
        onKeyPress={handleEnter}
        value={query}
        placeholder={t('translation:filter.search.message') as string}
        type="text"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" disableRipple onClick={handleSearch}>
                <Icon type="search"/>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SearchInput;
