// noinspection AllyJsxHardcodedStringInspection

import React, {FC, useEffect, useRef, useState} from 'react';
import HeaderHistory from './headerHistory/HeaderHistory';
import {Icon} from '../../default_components';
import './searchField.scss';
import {localStorageKeys} from '../../../routes';
import Search from '../../default_components/assets/icons/Search.svg';
import {t} from 'i18next';
import {useActions} from '../../../hooks/redux';
import useNavigateWithLocale from '../../../hooks/useNavigateWithLocale';

export enum OPEN_HEADER {
  HEADER_HISTORY = 'header-history',
}

const SearchField: FC = () => {
  const refInput = useRef<HTMLInputElement | null>(null);
  const navigateWithLocale = useNavigateWithLocale();
  const {setQuery: setStoredQuery} = useActions();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [overlay, setOverlay] = useState<string | boolean>(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState<any>('');

  useEffect(() => {
    validateSearch();
  }, [searchQuery]);

  const hideOverlay = (): void => setOverlay(false);

  const showHeaderHistory = (): void => {
    !overlay && setOverlay(OPEN_HEADER.HEADER_HISTORY);

    !(overlay === OPEN_HEADER.HEADER_HISTORY) && setOverlay(OPEN_HEADER.HEADER_HISTORY);
  };

  const validateSearch = (): boolean => {
    let len = searchQuery.split(' ').join('').length;
    const regex = /^(?=.*[a-zA-Zа-яА-ЯёЁა-ჰ0-9])[a-zA-Zа-яА-ЯёЁა-ჰ0-9()\- /]+$/;

    if (searchQuery.length > 0) {
      const trimmedQuery = searchQuery.trim();

      if (trimmedQuery.includes(',')) {
        setSearchErrorMessage(t('translation:header.search_field_error_format.message'));
        setOverlay(OPEN_HEADER.HEADER_HISTORY);
        return false;
      }

      if (len < 3) {
        setSearchErrorMessage(t('translation:header.search_field_error_length.message'));
        setOverlay(OPEN_HEADER.HEADER_HISTORY);
        return false;
      }

      if (trimmedQuery.length > 30) {
        setSearchErrorMessage(t('translation:header.search_field_error_max_length.message'));
        setOverlay(OPEN_HEADER.HEADER_HISTORY);
        return false;
      }

      if (!regex.test(trimmedQuery)) {
        setSearchErrorMessage(t('translation:header.search_field_error_invalid_chars.message'));
        setOverlay(OPEN_HEADER.HEADER_HISTORY);
        return false;
      }
    }

      setSearchErrorMessage('');

      return true;
  };

  const search = (query: string): void => {
    setSearchQuery(query);
    if (!validateSearch()) {
      return;
    }

    const searchHistory = localStorage.getItem(localStorageKeys.search);
    if (searchHistory) {
      if (searchHistory.includes(',')) {
        const updatedSearchHistory =
          searchHistory
            .split(',')
            .filter(item => item.toLowerCase() !== query.toLowerCase())
            .join() +
          ',' +
          query;

        !(updatedSearchHistory === searchHistory) &&
        localStorage.setItem(localStorageKeys.search, updatedSearchHistory);
      } else {
        !searchHistory.includes(query) &&
        localStorage.setItem(localStorageKeys.search, searchHistory + ',' + query);
      }
    } else {
      localStorage.setItem(localStorageKeys.search, query);
    }

    navigateWithLocale(`/category/all/${query}`);
    setStoredQuery(query);

    hideOverlay();
    window.scroll(0, 0);
    setOverlay('');
  };

  const searchByPress = (target: any): boolean | void => {
    searchQuery.length >= 3 && validateSearch() && target.charCode === 13 && search(searchQuery);
  };

  return (
    <React.Fragment>
      <div className={`search-field-overlay ${overlay && 'show'}`} onClick={hideOverlay} />

      <div className={`search-field-frame ${overlay && 'show'}`}>
        <div className="search-field-container">
          <input
            ref={refInput}
            onClick={showHeaderHistory}
            onChange={e => setSearchQuery(e.target.value)}
            onKeyPress={searchByPress}
            placeholder={t('translation:header.search_field_placeholder.message') || 'Search...'}
            type="text"
            data-cy="header-search-bar"
          />

          <div
            className="search-btn"
            onClick={() => search(searchQuery)}
            data-cy="header-search-button"
          >
            <Icon type="search"/>
          </div>
        </div>

        <HeaderHistory search={search} overlay={overlay} searchErrorMessage={searchErrorMessage} />
      </div>

      <div
        className="search-btn__small-resolution"
        onClick={showHeaderHistory}
        data-cy="header-search-button"
      >
        <img src={Search} alt="Search icon" />
      </div>
    </React.Fragment>
  );
};

export default SearchField;
