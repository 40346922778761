import React, { FC, useEffect, useState } from 'react';
import './filterPosts.scss';
import PostCard from '../../default_components/postCard/PostCard';
import ChipsContainer from './chipsContainer/ChipsContainer';
import { useActions, useAppSelector } from '../../../hooks/redux';
import { Skeletons } from '../../default_components';
import { ISearchRequest } from '../../../services/types';
import { Pagination, PaginationItem } from '@mui/material';
import { PaginationStyle } from '../../myFavoritePosts/PaginationStyle';
import FilterNavigation from './filterNavigation/FilterNavigation';
import NoPostsFound from './NoPostsFound';
import { categoryFields } from '../filterOptions';
import { useNavigate, useLocation, Link, useSearchParams } from 'react-router-dom';
import useUpdateQueryParams from '../../../hooks/useUpdateQueryParams';

interface IFilterPosts {
  isLoading: boolean;
  isLessDesktop?: boolean;
  search: ({}: ISearchRequest) => void;
  category_id: string | undefined;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const FilterPosts: FC<IFilterPosts> = ({ isLoading, search, category_id, toggleDrawer }) => {
  const { setPosts, setCurrentPage } = useActions();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isHandmade,
    query,
    posts,
    pagesCount,
    priceValue,
    postsStates,
    locationsStates,
    fieldsStates,
    sortBy,
    categoryIds,
  } = useAppSelector(state => state.filterReducer);

  const regions: string[] = Object.values(locationsStates);

  const [numberPage, setNumberPage] = useState<number>(() => {
    return parseInt(searchParams.get('page') || '1', 10);
  });

  const { updateQueryParams } = useUpdateQueryParams();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setNumberPage(value);
    setCurrentPage(value);
    setPosts(undefined);

    const requestObject: ISearchRequest = {
      page: value,
      category_id: categoryIds,
      sort_by: sortBy,
      used:
        (!postsStates.used && !postsStates.new) || (postsStates.used && postsStates.new)
          ? undefined
          : postsStates.used,
      location_id: regions.length ? regions : undefined,
      category_fields: categoryFields(fieldsStates),
      price: priceValue,
    };

    if (query) {
      requestObject.q = query;
    }

    if (isHandmade) {
      requestObject.handmade = isHandmade;
    }

    search(requestObject);

    updateQueryParams({
      page: value,
      sort_by: sortBy,
      used: postsStates.used,
      new: postsStates.new,
      handmade: isHandmade,
      price_min: (priceValue as number[])[0],
      price_max: (priceValue as number[])[1],
    });

  };

  useEffect(() => {
    const page = parseInt(searchParams.get('page') || '1', 10);
    setNumberPage(page);
    setCurrentPage(page);
  }, [location.search]);

  return (
    <div className="filter-posts__frame">
      <FilterNavigation category_id={category_id} search={search} toggleDrawer={toggleDrawer} />

      <ChipsContainer search={search} />

      <div className="filter-posts__container">
        {isLoading &&
          Array(20)
            .fill(0)
            .map((_, index) => <Skeletons.SkeletonPostCard filter key={index} />)}

        {posts?.map(post => (
          <PostCard key={post.id} post={post} filter />
        ))}

        {posts && posts.length === 0 && !isLoading && <NoPostsFound />}
      </div>

      {pagesCount > 1 && posts && posts.length > 0 && (
        <Pagination
          count={pagesCount}
          size="large"
          page={numberPage}
          variant="text"
          shape="rounded"
          onChange={handleChange}
          sx={PaginationStyle}
        />
      )}
    </div>
  );
};

export default FilterPosts;
